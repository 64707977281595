import LocomotiveScroll from "locomotive-scroll";
// import {
//     TweenMax,
//     Power2,
//     gsap.timeline
// } from 'gsap/TweenMax';
import gsap from "gsap";
import Vimeo from "@vimeo/player";
import "whatwg-fetch";
import * as moment from "moment";
import * as tz from "moment-timezone";

let scroll = this;
let isMobile = window.matchMedia("(max-width: 425px)").matches;

const banwagramIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="14" viewBox="0 0 20 14">
        <defs>
            <path id="banwagram-icon-a" d="M93.6666667,577 C95.0473785,577 96.1666667,578.119288 96.1666667,579.5 L96.166,582.047 L100.682302,578.821889 C101.233859,578.427919 102,578.82219 102,579.5 L102,587.833333 C102,588.511144 101.233859,588.905414 100.682302,588.511445 L96.166,585.285 L96.1666667,587.833333 C96.1666667,589.214045 95.0473785,590.333333 93.6666667,590.333333 L84.5,590.333333 C83.1192881,590.333333 82,589.214045 82,587.833333 L82,579.5 C82,578.119288 83.1192881,577 84.5,577 L93.6666667,577 Z M93.6666667,578.666667 L84.5,578.666667 C84.0397627,578.666667 83.6666667,579.039763 83.6666667,579.5 L83.6666667,587.833333 C83.6666667,588.293571 84.0397627,588.666667 84.5,588.666667 L93.6666667,588.666667 C94.126904,588.666667 94.5,588.293571 94.5,587.833333 L94.5,579.5 C94.5,579.039763 94.126904,578.666667 93.6666667,578.666667 Z M100.333333,581.119324 L96.7670542,583.666667 L100.333333,586.214009 L100.333333,581.119324 Z"/>
        </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(-82 -577)">
            <use fill="#CBDFE1" fill-rule="nonzero" xlink:href="#banwagram-icon-a"/>
        </g>
    </svg>
`;

jQuery(document).ready(function ($) {
	consoleMessage: {
		console.log(
			"%cCreated by %cTWK%cwww.thewebkitchen.co.uk",
			"background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;",
			"background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;",
			"background: #FFFFFF; padding: 5px 10px;"
		);
	}

	floatingTestimonialLink: {
		const closeTestimonial = document.querySelector(
			".floating-testimonial-link__close"
		);
		console.info("closeTestimonial");
		console.info(closeTestimonial);
		if (closeTestimonial) {
			closeTestimonial.addEventListener("click", function () {
				const testimonialTray = document.querySelector(
					".floating-testimonial-link"
				);
				gsap.to(testimonialTray, { autoAlpha: 0, y: "100px" });
			});
		}
	}
	preloader: {
		function loadHomeSliderVideos() {
			const vimeoDestinations = document.querySelectorAll(".js-vimeo-iframe");
			let playerOptions = {
				id: isMobile
					? vimeoDestinations[0].dataset.mobileVideoId
					: vimeoDestinations[0].dataset.videoId,
				background: true,
			};

			// load first video then load the rest whilst that loads
			let firstPlayer = new Vimeo(`video-slider-vimeo-1`, playerOptions);

			const firstVideoBufferEnd = new Promise((resolve, reject) => {
				firstPlayer.on("bufferend", () => {
					console.log("first slider vid stopped buffering");
					resolve();
				});
			});

			firstVideoBufferEnd.then(() => {
				const vimeoDestinationsArray = [...vimeoDestinations].slice(1);

				vimeoDestinationsArray.forEach((destination, i) => {
					const videoId = isMobile
						? destination.dataset.mobileVideoId
						: destination.dataset.videoId;

					playerOptions.id = parseInt(videoId);

					let player = new Vimeo(`video-slider-vimeo-${i + 2}`, playerOptions);
				});
			});

			return firstVideoBufferEnd;
		}

		function preloaderAnimationsBeforeVideoLoad() {
			let firstVideoBufferEnd = loadHomeSliderVideos();

			let preloader = document.querySelector(".js-preloader");
			let { showVideo } = preloader.dataset;

			let logo = document.querySelector(".preloader__logo");
			let location = document.querySelector(".preloader__location");
			let spinner = document.querySelector(".preloader__spinner");

			let beforeVideoTimeline = new gsap.timeline();

			beforeVideoTimeline
				.to(logo, 0.5, {
					opacity: 1,
					ease: "power2.inOut",
				})
				.to(location, 0.5, {
					opacity: 1,
					ease: "power2.inOut",
				});

			let iframe = document.querySelector(".js-preloader-video");
			let preloaderPromises = [];
			let video;
			let videoObj;

			if (!isMobile && showVideo === "true") {
				// do this here so video is loaded before it plays
				video = new Vimeo("preloader-video", {
					id: iframe.dataset.videoId,
					background: true,
				});

				videoObj = {
					iframe: iframe,
					video: video,
					videoDuration: 0,
				};

				// temporary event monitoring for performance improvements - remove when satisfied with loading time
				video.on("bufferstart", function () {
					console.log("started buffering");
				});

				video.on("loaded", function () {
					console.log("video loaded");
				});

				video.play().then(() => {
					console.log("video played");
				});

				video.getDuration().then((duration) => {
					videoObj.videoDuration = duration;
				});

				const bufferEnded = new Promise((resolve, reject) => {
					video.on("bufferend", function () {
						console.log("ended buffering");
						resolve();
					});
				});

				preloaderPromises.push(bufferEnded);
			} else {
				preloaderPromises.push(firstVideoBufferEnd);
			}

			const minimumTimeout = new Promise((resolve, reject) => {
				setTimeout(() => {
					console.log("minimum time passed");
					resolve();
				}, 3000);
			});

			preloaderPromises.push(minimumTimeout);

			const testimonialTray = document.querySelector(
				".floating-testimonial-link"
			);
			Promise.all(preloaderPromises)
				.then((results) => {
					if (isMobile || showVideo === "false") {
						let background = document.querySelector(".preloader__background");
						let afterVideoTimeline = new gsap.timeline();

						afterVideoTimeline
							.to([location, spinner, background, logo], 0.75, {
								opacity: 0,
								ease: "power2.inOut",
							})
							.add(function () {
								$(".video-slider")[0].slick.slickGoTo(0);
							}, "-=0.5")
							.add(function () {
								iframe.remove();
								document.querySelector(".js-preloader").remove();
								scroll.update();
								console.info("testimonialTray1");
								console.info(testimonialTray);
								if (testimonialTray) {
									gsap.to(testimonialTray, { x: 0 });
								}
							});
					} else {
						preloaderAnimationsAfterVideoLoad(
							logo,
							location,
							spinner,
							videoObj
						);
						// loadHomeSliderVideos();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}

		function preloaderAnimationsAfterVideoLoad(
			logo,
			location,
			spinner,
			videoObj
		) {
			let { iframe, video, videoDuration } = videoObj;

			let background = document.querySelector(".preloader__background");
			let text = document.querySelector(".preloader__text");
			let skip = document.querySelector(".preloader__skip");
			let afterVideoTimeline = new gsap.timeline();
			const testimonialTray = document.querySelector(
				".floating-testimonial-link"
			);

			afterVideoTimeline
				.to([location, spinner, background], 0.75, {
					opacity: 0,
					ease: "power2.inOut",
				})
				.add(function () {
					console.log("video should play");

					video
						.setCurrentTime(0)
						.then(function (seconds) {
							console.log("video reset/played");
						})
						.catch(function (error) {
							console.log(error);
						});

					console.log("should be after video played");
				}, "-=0.75")
				.to(skip, 0.75, {
					opacity: 1,
					ease: "power2.inOut",
				})
				.addLabel("videoPlayed")
				.to(
					text,
					0.5,
					{
						opacity: 1,
						ease: "power2.inOut",
					},
					"videoPlayed+=2.5"
				)
				// fade the video out 1 second before it ends
				.addLabel("delay")
				.to({}, videoDuration - 5, {})
				.addLabel("hide_preloader")
				.to([iframe, logo, text, skip], 0.5, {
					opacity: 0,
					ease: "power2.inOut",
				})
				.add(function () {
					$(".video-slider")[0].slick.slickGoTo(0);
				}, "-=0.5")
				.add(function () {
					iframe.remove();
					document.querySelector(".js-preloader").remove();
					scroll.update();
					console.info("testimonialTray1");
					console.info(testimonialTray);
					if (testimonialTray) {
						gsap.to(testimonialTray, { x: 0 });
					}
				});

			document
				.querySelector(".preloader__skip")
				.addEventListener("click", function () {
					console.info("skip");
					afterVideoTimeline.seek("hide_preloader");
				});
		}

		function animatePreloader() {
			let logo = document.querySelector(".preloader__logo");
			let location = document.querySelector(".preloader__location");
			let spinner = document.querySelector(".preloader__spinner");
			let background = document.querySelector(".preloader__background");

			let bannerVideo = document.querySelector(".js-home-video");

			const bannerVideoLoaded = new Promise((resolve, reject) => {
				bannerVideo.addEventListener(
					"canplay",
					function () {
						console.log("video can play");
						resolve();
					},
					false
				);
			});

			const minimumPreloaderTime = new Promise((resolve, reject) => {
				setTimeout(() => {
					console.log("minimum time passed");
					resolve();
				}, 50);
			});

			let preloaderPromises = [bannerVideoLoaded, minimumPreloaderTime];

			let preloaderTimelime = new gsap.timeline();

			// Fade in logo and location text for preloader
			preloaderTimelime
				.to(logo, 0.5, {
					opacity: 1,
					ease: "power2.inOut",
				})
				.to(location, 0.5, {
					opacity: 1,
					ease: "power2.inOut",
				});

			const testimonialTray = document.querySelector(
				".floating-testimonial-link"
			);
			// Once all promises have resolved (i.e. video has buffered AND minimum time has passed), fade out the preloader
			Promise.all(preloaderPromises)
				.then((result) => {
					preloaderTimelime
						.to([logo, location, spinner], 0.5, {
							opacity: 0,
							ease: "power2.inOut",
						})
						.add(function () {
							// reset banner video before preloader fades out
							bannerVideo.currentTime = 0;

							//                    bannerVideo.setCurrentTime(0).then(seconds => {
							//
							//                    })
						})
						.to(background, 0.5, {
							opacity: 0,
							ease: "power2.inOut",
						})
						.add(function () {
							document.querySelector(".js-preloader").remove();
							scroll.update();
							if (testimonialTray) {
								gsap.to(testimonialTray, { x: 0 });
							}
						});
				})
				.catch((error) => {
					console.error(error);
				});
		}

		if (document.querySelector(".js-preloader")) {
			animatePreloader();
		}
	}
	banwagram: {
		function appendVideos(teasersHtml) {
			const destination = document.querySelector(".js-append-teasers");

			destination.insertAdjacentHTML("beforeend", teasersHtml);

			//            scroll.update();

			// Vimeo popups for newly created elements
			$(".magnific-video").magnificPopup({
				type: "iframe",
				mainClass: "mfp-fade",
				removalDelay: 160,
				preloader: false,
				fixedContentPos: true,
				fixedBgPos: true,
				overflowY: "hidden",
				autoFocusLast: false,
				iframe: {
					markup:
						'<div class="mfp-iframe-scaler">' +
						'<div class="mfp-close"></div>' +
						'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
						"</div>",
					patterns: {
						youtu: {
							index: "youtu.be",
							id: function (url) {
								// Capture everything after the hostname, excluding possible querystrings.
								var m = url.match(/^.+youtu.be\/([^?]+)/);

								if (null !== m) {
									return m[1];
								}

								return null;
							},
							// Use the captured video ID in an embed URL.
							// Add/remove querystrings as desired.
							src: "//www.youtube.com/embed/%id%?autoplay=1&rel=0",
						},
						youtube: {
							index: "youtube.com/",
							id: "v=",
							src: "https://www.youtube.com/embed/%id%?autoplay=1",
						},
					},
				},
			});
		}

		function fetchVideos() {
			const vimeoCredentials = {
				albumId: "6577325",
				userId: "20613154",
				token: "2eec1ff6d592465a627a0cee85c84f2b",
			};

			const url = `https://api.vimeo.com/users/${vimeoCredentials.userId}/albums/${vimeoCredentials.albumId}/videos`;

			const fetchData = {
				method: "GET",
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${vimeoCredentials.token}`,
					"Content-Type": "application/json",
				},
			};

			let teasersHtml = [];

			fetch(url, fetchData)
				.then((response) => response.json())
				.then((data) => {
					const { data: videos } = data;

					videos.forEach((video) => {
						const { name, link, created_time: fullDate, description } = video;
						const { sizes } = video.pictures;

						const imageSize = sizes.find((size) => size.width === 640);

						const { link: imageUrl } = imageSize;

						let date = new Date(fullDate);
						let dateString = date.toLocaleDateString();
						let dateArray = dateString.split("/");
						let dateFormatted = `${dateArray[0]}.${dateArray[1]}.${dateArray[2]}`;

						teasersHtml.push(`
                            <div class="col-md-6 col-lg-4 m-b-60">
                                <a class="magnific-video" href="${link}">
                                    <div class="teaser teaser--banwagram">
                                        <div class="teaser__image m-b-10" style="background-image: url(${imageUrl});">
                                            <img class="teaser__play-icon" src="${
																							php_vars.themeDirUrl
																						}/assets/img/play.svg">
                                        </div>
                                        <div class="teaser__content">
                                            <h3 class="title title--sm dark-grey-color m-b-10">${name}</h3>
                                            <div class="teaser__details">
                                                <p class="blue-color m-b-0">${banwagramIcon}<span>${
							description || ""
						}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        `);
					});

					appendVideos(teasersHtml.join(""));
				})
				.catch((err) => {
					console.log("Fetch Error:", err);
				});
		}

		// banner slider
		function loadBannerSliderVideos() {
			const vimeoDestinations = document.querySelectorAll(".js-vimeo-iframe");

			let playerOptions = {
				id: isMobile
					? vimeoDestinations[0].dataset.mobileVideoId
					: vimeoDestinations[0].dataset.videoId,
				background: true,
			};

			// load first video then load the rest whilst that loads
			let firstPlayer = new Vimeo(`video-slider-vimeo-1`, playerOptions);

			const firstVideoBufferEnd = new Promise((resolve, reject) => {
				firstPlayer.on("bufferend", () => {
					resolve();
				});
			});

			firstVideoBufferEnd.then(() => {
				const vimeoDestinationsArray = [...vimeoDestinations].slice(1);

				vimeoDestinationsArray.forEach((destination, i) => {
					const videoId = isMobile
						? destination.dataset.mobileVideoId
						: destination.dataset.videoId;

					playerOptions.id = parseInt(videoId);

					let player = new Vimeo(`video-slider-vimeo-${i + 2}`, playerOptions);
				});
			});
		}

		if (
			document
				.querySelector("body")
				.classList.contains("page-template-tpl-banwagram")
		) {
			fetchVideos();
			if (!isMobile) {
				loadBannerSliderVideos();
			} else {
				document.querySelector(".mobile-nav").classList.add("mobile-nav--dark");
			}
		}
	}
	smoothScroll: {
		$(document).on("click", ".js-scroll-to", function (event) {
			let scroll_to = $(this).data("scroll-to");

			console.info(scroll_to);

			const target = document.querySelector(scroll_to);

			scroll.scrollTo(target, -90);
		});
	}
	slider: {
		var slideWrapper = $(".video-slider"),
			iframes = slideWrapper.find(".embed-player"),
			lazyImages = slideWrapper.find(".slide-image"),
			lazyCounter = 0;

		// POST commands to YouTube or Vimeo API
		function postMessageToPlayer(player, command) {
			if (player == null || command == null) return;
			player.contentWindow.postMessage(JSON.stringify(command), "*");
		}

		// When the slide is changing
		function playPauseVideo(slick, control, currentSlideIndex, nextSlideIndex) {
			var currentSlide, nextSlide, slideType, startTime, player, video;

			currentSlide =
				control === "pause"
					? slick.find(
							'.slick-slide[data-slick-index="' + currentSlideIndex + '"]'
					  )
					: slick.find(
							'.slick-slide[data-slick-index="' + nextSlideIndex + '"]'
					  );
			slideType = currentSlide.attr("class").split(" ")[1];
			player = currentSlide.find("iframe").get(0);
			startTime = currentSlide.data("video-start");
			slideType = "vimeo";

			if (slideType === "vimeo") {
				switch (control) {
					case "play":
						postMessageToPlayer(player, {
							method: "setCurrentTime",
							value: 0,
						});
						postMessageToPlayer(player, {
							method: "play",
							value: 1,
						});
						break;
					case "pause":
						postMessageToPlayer(player, {
							method: "pause",
							value: 1,
						});
						break;
				}
			} else if (slideType === "youtube") {
				switch (control) {
					case "play":
						postMessageToPlayer(player, {
							event: "command",
							func: "mute",
						});
						postMessageToPlayer(player, {
							event: "command",
							func: "playVideo",
						});
						break;
					case "pause":
						postMessageToPlayer(player, {
							event: "command",
							func: "pauseVideo",
						});
						break;
				}
			} else if (slideType === "video") {
				video = currentSlide.find("video").get(0);

				if (video != null) {
					if (control === "play") {
						video.currentTime = 0;
						video.play();
					} else {
						video.pause();
					}
				}
			}
		}

		// Resize player
		function resizePlayer(iframes, ratio) {
			if (!iframes[0]) return;

			var win = $(".main-slider"),
				width = win.width(),
				playerWidth,
				height = win.height(),
				playerHeight,
				ratio = ratio || 16 / 9;

			iframes.each(function () {
				var current = $(this);

				if (width / ratio < height) {
					playerWidth = Math.ceil(height * ratio);

					current
						.width(playerWidth)
						.height(height)
						.css({
							left: (width - playerWidth) / 2,
							top: 0,
						});
				} else {
					playerHeight = Math.ceil(width / ratio);

					current
						.width(width)
						.height(playerHeight)
						.css({
							left: 0,
							top: (height - playerHeight) / 2,
						});
				}
			});
		}

		// DOM Ready

		// Initialize
		slideWrapper.on("init", function (slick) {
			slick = $(slick.currentTarget);

			setTimeout(function () {
				playPauseVideo(slick, "play", 0, 0);
			}, 200);

			resizePlayer(iframes, 16 / 9);
		});

		slideWrapper.on(
			"beforeChange",
			function (event, slick, currentSlide, nextSlide) {
				slick = $(slick.$slider);
				playPauseVideo(slick, "pause", currentSlide, nextSlide);

				// play next video
				playPauseVideo(slick, "play", currentSlide, nextSlide);

				resizePlayer(iframes, 16 / 9);
			}
		);

		//start the slider
		slideWrapper.slick({
			autoplay: true,
			autoplaySpeed: 11400,
			speed: 600,
			fade: true,
			arrows: false,
			dots: true,
			pauseOnHover: false,
			pauseOnFocus: false,
		});

		// Resize event
		$(window).on("resize.slickVideoPlayer", function () {
			resizePlayer(iframes, 16 / 9);
		});
	}
	magnific: {
		// Vimeo popups
		$(".magnific-video").magnificPopup({
			type: "iframe",
			mainClass: "mfp-fade",
			removalDelay: 160,
			preloader: false,
			fixedContentPos: true,
			fixedBgPos: true,
			overflowY: "hidden",
			autoFocusLast: false,
			iframe: {
				markup:
					'<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					"</div>",
				patterns: {
					youtu: {
						index: "youtu.be",
						id: function (url) {
							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match(/^.+youtu.be\/([^?]+)/);

							if (null !== m) {
								return m[1];
							}

							return null;
						},
						// Use the captured video ID in an embed URL.
						// Add/remove querystrings as desired.
						src: "//www.youtube.com/embed/%id%?autoplay=1&rel=0",
					},
					youtube: {
						index: "youtube.com/",
						id: "v=",
						src: "https://www.youtube.com/embed/%id%?autoplay=1",
					},
				},
			},
		});

		// Vimeo popups
		$(".magnific-inline").magnificPopup({
			type: "inline",
			mainClass: "mfp-fade",
			removalDelay: 160,
			preloader: false,
			fixedContentPos: true,
			fixedBgPos: true,
			overflowY: "hidden",
			autoFocusLast: false,
			closeMarkup: `<button title="%title%" type="button" class="mfp-close"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button>`,
		});

		// GALLERY IMAGE
		$(".magnific-gallery-image").magnificPopup({
			type: "image",
			closeOnContentClick: true,
			image: {
				verticalFit: true,
				titleSrc: function (item) {
					return item.el.attr("title");
				},
			},
			gallery: {
				enabled: true,
			},
			mainClass: "mfp-fade mfp-img-mobile",
			removalDelay: 160,
			preloader: false,
			fixedContentPos: true,
			fixedBgPos: true,
			overflowY: "hidden",
			autoFocusLast: false,
		});
	}
	externallinks: {
		$("a:not(.magnific-video):not(.magnific-gallery-image)").each(function () {
			var a = new RegExp("/" + window.location.host + "/");
			if (!a.test(this.href)) {
				$(this).click(function (event) {
					event.preventDefault();
					window.open(this.href, "_blank");
				});
			}
		});
		pdfs: {
			///OPEN PDFS IN NEW WINDOW
			$(function () {
				$('a[href$=".pdf"]').prop("target", "_blank");
			});
		}
	}
	search: {
		$(".search-icon").on("click", function (e) {
			$(".searchform").toggleClass("search-open");
			$(this).toggleClass("submit-zindex");
			$(".select-lang").removeClass("transform-height");
			$("#menu-main-menu-top, .lang_dropdown").fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$(".close-search").fadeIn();
			}, 300);
		});
		$(".close-search").on("click", function (e) {
			$(this).fadeOut();
			$(".searchform").removeClass("search-open");
			$(".search-icon").removeClass("submit-zindex");
			$("#menu-main-menu-top, .lang_dropdown").fadeIn();
		});
	}
	loadingAnimations: {
		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop(),
			};
			viewport.bottom = viewport.top + win.height() - 100;
			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();
			return !(viewport.bottom < bounds.top || viewport.top > bounds.bottom);
		};
	}
	onLoadAnims: {
		$(document).ready(function () {
			$(".off-screen").each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass("off-screen--hide");
				}
			});
		});
	}
	weather: {
		function getWeatherIcon(icon) {
			let iconsMap = {
				sun: ["clear-day", "Clear"],
				storm: ["wind", "thunderstorm", "tornado", "Thunderstorm"],
				rain: ["rain", "sleet", "snow", "hail", "Rain", "Drizzle"],
				cloud: ["partly-cloudy-day", "cloudy", "fog", "Clouds"],
				night: ["clear-night", "partly-cloudy-night"],
			};

			let currentWeatherIcon = Object.keys(iconsMap).filter((key) =>
				iconsMap[key].includes(icon)
			);

			return currentWeatherIcon;
		}

		function setUpForecast() {
			let days = php_vars.darkskyResponse.list;

			let forecastElements = document.querySelectorAll(".js-forecast");
			console.info(forecastElements);
			let firstDate = "test";
			let div = 0;
			if (!forecastElements.length) return;
			[...days].forEach((day, i) => {
				if (day.dt_txt && day.dt_txt.split(" ")[0] != firstDate && div < 5) {
					let temperatureHigh = day.main.temp_max;
					let icon = day.weather[0].main;
					let time = day.dt_txt;
					firstDate = time.split(" ")[0];

					let dateLocation =
						forecastElements[div].querySelector(".js-forecast-date");
					let weatherLocation = forecastElements[div].querySelector(
						".js-forecast-weather"
					);

					// set Temperature
					let roundedTempHigh = Math.round(temperatureHigh);

					weatherLocation.querySelector(
						"p span"
					).innerHTML = `${roundedTempHigh}°C ${weatherLocation.innerHTML}`;

					let iconLocations = weatherLocation.querySelectorAll("svg");

					// set icon
					let currentIcon = getWeatherIcon(icon);
					let currentIconSvg = weatherLocation.querySelector(
						`svg.${currentIcon[0]}`
					);

					currentIconSvg.style.display = "block";

					// set date
					let formattedDate = moment(time).format("dddd Do");
					dateLocation.innerHTML = `${formattedDate}`;
					div++;
				}
			});
		}

		function setUpTemperature() {
			// console.info(php_vars.darkskyResponse.list[0].weather);
			// console.info(php_vars.darkskyResponse);

			let response = php_vars.darkskyResponse;
			let currentWeather = response.currently;

			// let { temperature, icon } = currentWeather;

			// set temperature
			let roundedTemp = Math.round(
				php_vars.darkskyResponse.list[0].main.temp_max
			);
			let tempLocations = document.querySelectorAll(".js-temperature span");

			[...tempLocations].forEach(
				(tempLocation) =>
					(tempLocation.innerHTML = `${roundedTemp}°C ${tempLocation.innerHTML}`)
			);

			// set icon
			let iconLocations = document.querySelectorAll(".js-temperature");

			let currentIcon = getWeatherIcon(
				php_vars.darkskyResponse.list[0].weather[0].main
			);

			[...iconLocations].forEach((iconLocation) => {
				let currentIconSvg = iconLocation.querySelector(
					`svg.${currentIcon[0]}`
				);
				currentIconSvg.style.display = "block";
			});
		}

		setUpTemperature();
		setUpForecast();
	}

	locationWeatherChartTabs: {
		function togglChartTabs(clickedTab, allTabs) {
			var allTabContentBlocks = document.querySelectorAll(
				".js-weather-tab-content"
			);
			var activeTabContent = document.querySelector(
				'.js-weather-tab-content[data-chart="' + clickedTab.dataset.chart + '"]'
			);
			if (allTabContentBlocks.length && allTabs.length) {
				// doing the same thing (removing class) on items from both arrays so better to combine them into one rather than doing 2 loops
				[...allTabs, ...allTabContentBlocks].forEach(function (item) {
					item.classList.remove("active");
				});

				clickedTab.classList.add("active");

				activeTabContent.classList.add("active");
			}
		}

		function setupChartTabs() {
			let tabs = document.querySelectorAll(".js-weather-tabs li");

			if (!tabs.length) return;
			[...tabs].forEach((tab) => {
				tab.addEventListener(
					"click",
					function () {
						togglChartTabs(tab, tabs);
					},
					false
				);
			});
		}

		setupChartTabs();
	}
	// general function for all tabs rather than making them specific
	// may have to be updated if there are more than one set of tabs on a page
	tabs: {
		function toggleTabs(allTabs, clickedTab) {
			// remove active class from all tabs + tab content
			let allTabContent = document.querySelectorAll(".js-tab-content");

			[...allTabs, ...allTabContent].forEach((element) =>
				element.classList.remove("active")
			);

			let activeTabContent = document.querySelector(
				`.js-tab-content[data-tab-id="${clickedTab.dataset.tabId}"]`
			);

			clickedTab.classList.add("active");
			activeTabContent.classList.add("active");

			scroll.update();
		}

		function setUpTabs() {
			let tabs = document.querySelectorAll(".js-tabs li");

			if (tabs)
				[...tabs].forEach((tab) =>
					tab.addEventListener("click", (e) => toggleTabs(tabs, tab), false)
				);
		}

		setUpTabs();
	}

	testimonialSlider: {
		$(".testimonial-slider").slick({
			dots: true,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 7000,
			pauseOnHover: false,
			draggable: false,
		});
		$(".diary-slider").slick({
			dots: false,
			arrows: true,
			fade: false,
			autoplay: true,
			autoplaySpeed: 7000,
			pauseOnHover: false,
			draggable: false,
		});
	}
	mobileMenu: {
		// Mobile Menu
		let menuState = "closed";

		function handleHamburgerClick() {
			let body = document.querySelector("body");
			let topNav = document.querySelector(".top-nav");
			let mobileNav = document.querySelector(".mobile-nav");
			let pageWrap = document.querySelector("#page-wrap");
			let mobileWrap = document.querySelector(".mobile-wrap");

			if (menuState === "closed") {
				menuState = "open";
				body.classList.add("menu-open");
				topNav.classList.add("menu-open");
				mobileNav.classList.add("menu-open");
				pageWrap.classList.add("menu-open");
				mobileWrap.classList.add("menu-open");
				this.classList.add("menu-open");
			} else {
				menuState = "closed";
				topNav.classList.remove("menu-open");
				mobileNav.classList.remove("menu-open");
				pageWrap.classList.remove("menu-open");
				mobileWrap.classList.remove("menu-open");
				this.classList.remove("menu-open");

				// needs to happen when menu is closed
				body.classList.remove("menu-open");
			}
		}

		function initMobileMenu() {
			let hamburger = document.querySelector(".js-hamburger");

			hamburger.addEventListener("click", handleHamburgerClick, false);
		}

		initMobileMenu();
	}
	mailchimp: {
		let mc1Submitted = false;
		$("#mc-embedded-subscribe-form").on("submit reset", function (event) {
			if ("submit" === event.type) {
				mc1Submitted = true;
			} else if ("reset" === event.type && mc1Submitted) {
				console.log("success");

				$(".popup--signup").addClass("success");
				$(".popup__top, .popup__form").css("display", "none");
				$(".popup__success").css("display", "flex");
			}
		});
	}
});

function scrollOnWeatherNavigate() {
	const urlParams = new URLSearchParams(window.location.search);
	const myParam = urlParams.get("l");
	const target = document.querySelector("#weather");

	if (myParam == "weather") scroll.scrollTo(target);
}

function initLocomotiveScroll() {
	$(window).scrollTop(0);

	setTimeout(() => {
		scroll = new LocomotiveScroll({
			el: document.querySelector("#js-scroll"),
			smooth: true,
		});

		scroll.on("call", (value, way, obj) => {
			$(".page-navigation div").removeClass("active");
			$('[data-scroll-to="#' + obj.el.id + '"]').addClass("active");
			$(document).trigger(value);
		});

		if (scroll.isMobile) {
			document.querySelector("body").style.overflow = "visible";
		}

		$(".full-width-image-slider").each(function () {
			var slider = $(this);

			slider.on("init", function (slick) {
				console.info("slick initiated");

				scroll.update();

				var resizeEvent = window.document.createEvent("UIEvents");

				resizeEvent.initUIEvent("resize", true, false, window, 0);

				window.dispatchEvent(resizeEvent);
			});

			slider.slick({
				dots: true,
				arrows: false,
				fade: true,
				autoplay: true,
				autoplaySpeed: 7000,
				pauseOnHover: false,
				draggable: false,
			});

			slider.on(
				"beforeChange",
				function (event, slick, currentSlide, nextSlide) {
					console.log("beforeSlide");

					var resizeEvent = window.document.createEvent("UIEvents");

					resizeEvent.initUIEvent("resize", true, false, window, 0);

					window.dispatchEvent(resizeEvent);
				}
			);
		});

		scrollOnWeatherNavigate();
	}, 50);
}

window.addEventListener("load", initLocomotiveScroll, false);
